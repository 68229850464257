// DropFileInput.jsx
import React, { ChangeEvent, useRef, useState } from 'react';
import { Button, IconButton, Text } from 'react-native-paper';
import AwesomeIcon from 'react-native-vector-icons/FontAwesome';
import '../css/dropfile.css';
import { Image, View } from 'react-native';

export const DropFileInput = (props: { onFileChange: (file: File | null) => void, mimetype: string }) => {

    const wrapperRef = useRef<HTMLDivElement>(null);

    const [file, setFile] = useState<File | null>(null);

    const onDragEnter = () => wrapperRef.current!.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current!.classList.remove('dragover');

    const onDrop = () => wrapperRef.current!.classList.remove('dragover');

    const onFileDrop = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFile = (e.target as HTMLInputElement).files![0];
        if (newFile) {
            setFile(newFile);
            props.onFileChange(newFile);
        }
    }

    const fileRemove = () => {
        setFile(null);
        props.onFileChange(null);
    }


    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label" style={{ border: "1px dotted rgb(230, 225, 229)", height: 200, alignContent: "center", color: "rgb(230, 225, 229)" }}>
                    <p>Klik eller træk en fil her for at uploade</p>
                </div>
                <input type="file" accept={props.mimetype} value="" onChange={onFileDrop} />
            </div>
            {
                file != null ? (
                    <View>
                        {
                            <View style={{position: "relative", display: "flex", flexDirection: "row", marginBottom: 10, padding: 15, borderRadius: 20, alignSelf: "center"}}>
                                <Button icon={(props) =>  <AwesomeIcon  {...props} size={35} name='file'/>}> </Button>
                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <Text style={{ margin: 0, fontWeight: "500" }}>{file.name}</Text>
                                    <Text style={{ margin: 0, fontWeight: "500" }}>{Math.round(file.size / 1024 / 1024)}MB</Text>
                                </View>
                                <Button onPress={fileRemove}>
                                    x
                                </Button>
                            </View>

                        }
                    </View>
                ) : null
            }
            
        </>
    );
}
