import { View } from "react-native"
import { TextInput, Text, Button, Divider } from "react-native-paper"
import { AppInfo, UploadErrorResponseUpdateData } from "../../../models"
import { useContext, useEffect, useState } from "react"
import { AuthenticatedAxios } from "../../../auth"
import { getApiUrl } from "../../../configuration"
import { signOut } from "firebase/auth"
import { AuthContext } from "../../../context"
import { PaperSelect } from "react-native-paper-select"
import { ListItem } from "react-native-paper-select/lib/typescript/interface/paperSelect.interface"

export const AppUploadAdditionalInfoForm = ({ displayErrorMessage, onInputChanged, onSubmit, formData, requestPending }: { displayErrorMessage: boolean, onInputChanged: (key: string, value: any) => void, formData: UploadErrorResponseUpdateData, requestPending: boolean, onSubmit: () => void }) => {
    const { auth } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<ListItem[]>([])
    const [selectedOption, setSelectedOption] = useState<string>("");
    useEffect(() => {
        loadApps();
    }, [1])
    const loadApps = async () => {
        setLoading(true);
        try {
            var apps = await AuthenticatedAxios.get<AppInfo[]>(`${getApiUrl()}/AppVersions/GetApps`);
            var opTemp: ListItem[] = [];
            apps.data.map((el) => opTemp.push({ _id: el.apkName, value: el.apkName }));
            setOptions([...opTemp]);
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
        finally {
            setLoading(false);
        }
    }
    return (
        <>
            {
                displayErrorMessage &&
                <Text style={{ marginBottom: 10, color: "red" }}>Appen kunne ikke læses. Indtast oplysninger manuelt</Text>
            }

            <PaperSelect
                containerStyle={{ marginBottom: 10 }}
                label="Pakkenavn"
                arrayList={options}
                multiEnable={false}
                selectedArrayList={[]}
                value={selectedOption}
                onSelection={(val) => {
                    setSelectedOption(val.text);
                    onInputChanged("apkName", val.text)
                }}
            />
            <Text style={{ marginBottom: 10 }}>Eller skriv manuelt (com.peasoup.?)</Text>
            <TextInput disabled={(selectedOption != null || selectedOption != undefined) && selectedOption != ""} style={{ marginBottom: 20 }} placeholder='Pakkenavn' value={formData?.apkName} onChangeText={(e) => {
                onInputChanged("apkName", e)
            }} />
            <Divider style={{marginBottom: 20}}/>
            <Text style={{ marginBottom: 10 }}>App version (fx 1.0.0)</Text>
            <TextInput style={{ marginBottom: 10 }} placeholder='App version' value={formData?.versionName} onChangeText={(e) => {
                onInputChanged("versionName", e)
            }} />

            <Text style={{ marginBottom: 10 }}>App versioncode/buildnummer (fx 1)</Text>
            <TextInput style={{ marginBottom: 10 }} placeholder='App versioncode' value={formData?.versionCode} onChangeText={(e) => {
                onInputChanged("versionCode", e)
            }} />

            <View style={{ flex: 1, alignItems: "center", marginTop: 20 }}>
                <Button loading={requestPending || loading} onPress={onSubmit} mode='outlined'>Fortsæt</Button>
            </View>
        </>
    )
}