import { TextInput, Button, Text } from "react-native-paper";

export const AppVersionNotesForm = ({ versionNotes, setVersionNotes, requestPending, onSubmit }: { versionNotes: string, setVersionNotes: (e: string) => void, requestPending: boolean, onSubmit: () => void }) => {
    return (
        <>
            <Text style={{ marginBottom: 10 }}>Hvad er blevet opdateret?</Text>
            <TextInput style={{ marginBottom: 10 }} multiline value={versionNotes} onChangeText={(e) => { setVersionNotes(e); }}></TextInput>
            <Button loading={requestPending} onPress={onSubmit} mode='outlined'>Gem</Button>
        </>
    )
}