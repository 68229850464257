export class AppInfo {
    id: number;
    userFacingName: string;
    apkName: string;
    latestVersion: string;
}
export class AppVersion{
    id: number;
    versionName: string;
    versionCode: string;
    versionNotes: string;
    createdAt: string;
}