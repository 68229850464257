import axios, { AxiosResponse, isAxiosError } from 'axios';
import * as StorageAuth from './Auth';
import { getApiKey } from '../configuration';
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(async (config: any) => {
    try {
        console.log("token");
        var token = await getAuth(getApp()).currentUser?.getIdToken(); // await StorageAuth.getCachedTokenAsync();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        config.headers["agent"] = "peasoup-versioning-backend";
        config.headers["apiKey"] = `${getApiKey()}`;
    } catch (err) {
        throw err;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(async (response: AxiosResponse) => {
    return response;
}, (error) => {
    if (!error || !error.response) {
    }

    return Promise.reject(error);
});

export {
    axiosInstance as AuthenticatedAxios
};