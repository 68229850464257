import { ThemedView } from "./ThemedView";
import { useContext } from "react";
import { DeviceContext } from "../context";
import { DimensionValue, ViewStyle } from "react-native";
interface MainWrapperProps {
    style?: ViewStyle,
    marginWeb?: number | DimensionValue
}
export const MainWrapper = ({ children, style, marginWeb }: React.PropsWithChildren<MainWrapperProps>) => {
    const { ua, usingDevice } = useContext(DeviceContext)
    return (
        <ThemedView style={[{ paddingRight: (usingDevice ? 20 : 100), paddingLeft: (usingDevice ? 20 : 100), paddingTop: 40, paddingBottom: 40, marginLeft: (!usingDevice ? marginWeb : 0), marginRight: (!usingDevice ? marginWeb : 0) }, { ...style }]}>
            {children}
        </ThemedView>
    )
}