import { signOut } from "firebase/auth";
import { useContext, useState } from "react";
import { AuthenticatedAxios } from "../../auth";
import { getApiUrl, getApiKey } from "../../configuration";
import { AuthContext } from "../../context";
import { AppInfo, UploadErrorResponse, UploadErrorResponseUpdateData, UploadResponse } from "../../models";
import { AppVersionUploadModal } from "./AppVersionUploadModal";
import { AppUploadAdditionalInfoForm, AppVersionUserFacingNameForm, AppVersionFileInputFormIOS } from "./Forms";
import { v4 as uuid } from 'uuid';
import { Divider } from "react-native-paper";

export const IOSUploadModal = ({ visible, setUploadVisible, onAppUploaded }: { visible: boolean, setUploadVisible: (state: boolean) => void, onAppUploaded: () => void }) => {
    const { auth } = useContext(AuthContext);
    const [file, setFile] = useState<File | null>(null);
    const [requestPending, setRequestPending] = useState(false);
    const [appDidNotExist, setAppDidNotExist] = useState(false);
    const initialAppInfo = { apkName: "", versionCode: "", versionName: "", userFacingName: "" };
    const [appInfo, setAppInfo] = useState<UploadErrorResponseUpdateData>({ ...initialAppInfo })
    const [uploadResponse, setResponse] = useState<UploadResponse | null>(null);
    const BYTES_PER_CHUNK = 10485760; // 10MB chunk sizes.
    var start = 0;
    var part = 0;
    var SIZE = 0;
    var xhr = new XMLHttpRequest();
    var fileGuid = '';
    const hideModal = () => {
        setAppDidNotExist(false);
        setUploadVisible(false);
    };
    const onFileChange = (fileResult: File | null) => {
        setFile(fileResult);
    }
    function sendRequest() {
        if (!appInfo.apkName || !appInfo.versionCode || !appInfo.versionName)
            return;
        setRequestPending(true);
        SIZE = file!.size;
        start = 0;
        part = 0;
        xhr = new XMLHttpRequest();
        fileGuid = uuid();
        xhr.addEventListener("load", uploadComplete, false);
        uploadLargeFile();
    }
    const onAppInfoInputChange = (key: string, value: string) => {
        setAppInfo(prev => { return { ...prev, [key]: value } })
    }
    async function uploadComplete(event: any) {
        if (event.currentTarget.status === 200) {
            if (start < SIZE) {
                start = start + BYTES_PER_CHUNK;
                part++;
                uploadLargeFile();
            } else {
                setRequestPending(true);
                try {
                    var response = await AuthenticatedAxios.get<UploadResponse>(`${getApiUrl()}/AppVersions/UploadLargeFileCompletionIOS?guid=${fileGuid}&fileName=${file!.name}&apkName=${appInfo.apkName}&versionName=${appInfo.versionName}&versionCode=${appInfo.versionCode}`);
                    setResponse(response.data)
                    if (!response.data.appExisted) {
                        setAppDidNotExist(true);
                    }
                    else {
                        onAppUploaded();
                        setUploadVisible(false);
                    }

                } catch (error: any) {
                    if (error && error.response && error.response.status == 401 && auth)
                        signOut(auth)
                    else {
                        alert("Der skete en fejl under upload..");
                        console.log("Error during upload ", error);
                    }
                }
                finally {
                    setRequestPending(false);
                }
            }
        } else {
            console.log("Error uploading");
            setRequestPending(false);
        }

    }
    async function uploadLargeFile() {
        var blobFile = file!.slice(start, BYTES_PER_CHUNK + start);
        var fd = new FormData();
        fd.append("file", blobFile);
        fd.append("fileguid", fileGuid);
        fd.append('part', part.toString());
        fd.append('rawFileName', file!.name);
        fd.append('partsTotal', Math.ceil(SIZE / BYTES_PER_CHUNK).toString());
        xhr.open("POST", `${getApiUrl()}/AppVersions/UploadLargeFile?type=${"IOS"}`);
        var token = await auth?.currentUser?.getIdToken(); //StorageAuth.getCachedTokenAsync();
        if (token) {
            xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        }
        xhr.setRequestHeader("agent", "peasoup-versioning-backend");
        xhr.setRequestHeader("apiKey", `${getApiKey()}`);
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.send(fd);
    }
    const saveAppName = async () => {
        if (uploadResponse) {
            try {
                await AuthenticatedAxios.get(`${getApiUrl()}/AppVersions/UpdateAppName/${uploadResponse?.appId}?appName=${uploadResponse?.suggestedName}`);
                onAppUploaded();
                setUploadVisible(false);
            } catch (error: any) {
                if (error && error.response && error.response.status == 401 && auth)
                    signOut(auth)
            }
            setAppDidNotExist(false);
        }
    }
    if (appDidNotExist)
        return (
            <AppVersionUploadModal hideModal={hideModal} visible={visible}>
                <AppVersionUserFacingNameForm uploadResponse={uploadResponse} onNameChange={(e) => {
                    setResponse(prev => { return { ...prev!, suggestedName: e } })
                }} onSubmit={saveAppName} requestPending={requestPending} />
            </AppVersionUploadModal>
        );
    else
        return (
            <AppVersionUploadModal hideModal={hideModal} visible={visible}>
                <AppVersionFileInputFormIOS
                    onFileChange={onFileChange}
                    file={file}
                />
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <AppUploadAdditionalInfoForm
                    displayErrorMessage={false}
                    formData={appInfo}
                    onInputChanged={onAppInfoInputChange}
                    onSubmit={sendRequest}
                    requestPending={requestPending}
                />
            </AppVersionUploadModal>
        );
}