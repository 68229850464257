import { createContext, useEffect, useState } from "react";
import * as Device from 'expo-device';
class UAModel {
    model?: string | null;
    platform?: string | null;
    platformVersion?: string | null;
}
class DeviceContextModel{
    usingDevice: boolean;
    ua: any;
}
export const DeviceContext = createContext<DeviceContextModel>({ usingDevice: false, ua: null });

export const DeviceProvider = (props: any) => {
    const [ua, setUa] = useState<any>();
    const [usingDevice, setUsingDevice] = useState(false);
    useEffect(() => {
        getDeviceData();
    }, [props])
    const getDeviceData = async () => {
        var agentData = (navigator as any).userAgentData;
        try {
            var ua = await agentData.getHighEntropyValues(["model", "platform", "platformVersion", "uaFullVersion"]);
            if (ua && ua.model && ua.platformVersion) {
                var uModel = new UAModel();
                uModel.model = Device.modelName;
                uModel.platform = Device.osName;
                uModel.platformVersion = Device.osVersion;
                setUa(uModel);
                setUsingDevice(true);
            } else {
                setUa(ua);
            }
        } catch (error) {
            if (!ua || !ua.model || !ua.platformVersion) {
                var uModel = new UAModel();
                uModel.model = Device.modelName;
                uModel.platform = Device.osName;
                uModel.platformVersion = Device.osVersion;
                setUa(uModel);
                setUsingDevice(true);
            }
        }
    }
    return (
        <DeviceContext.Provider value={{usingDevice, ua}}>
            {props.children}
        </DeviceContext.Provider>
    )
}