import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../RootStackParamList";
import { MainWrapper, ThemedView } from "../components";
import { Button, TextInput } from "react-native-paper";
import { useContext, useState } from "react";
import { AuthContext } from "../context";
import { signInWithEmailAndPassword } from "firebase/auth";
import { User } from "../models";
export const Login = ({ navigation }: NativeStackScreenProps<RootStackParamList, "Login">) => {
    const [user, setUser] = useState<User>(new User())
    const authContext = useContext(AuthContext);
    const onChange = (key: string, value: any) => {
        setUser(prev => { return { ...prev, [key]: value } })
    }
    const doLogin = async () => {
        if (authContext.auth) {
            try {
                var result = await signInWithEmailAndPassword(authContext.auth, user.email, user.password);
                //var token = await result.user.getIdToken();
                //await Auth.cacheIdTokenAsync(token);

            } catch (error : any) {
                switch (error.code) {
                    case "auth/missing-email":
                        alert("Email er ikke indtastet");
                        break;
                    case "auth/missing-password":
                        alert("Password er ikke indtastet");
                        break;
                    case "auth/invalid-login-credentials":
                        alert("Email og Password passer ikke");
                        break;
                    default:
                        alert("Der skete en fejl, prøv igen eller kontakt udviklerne");
                        break;
                }
            }
        }
    }
    return (
        <MainWrapper style={{flex: 1}} marginWeb={"20%"}>
            <ThemedView style={{ padding: 20, flex: 1, overflow: "hidden", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <TextInput
                    label="Email"
                    value={user?.email}
                    mode="outlined"
                    inputMode="email"
                    textContentType="emailAddress"
                    autoComplete="email"
                    style={{ marginBottom: 10, width: "100%" }}
                    onChangeText={text => onChange("email", text)}
                />
                <TextInput
                    label="Password"
                    mode="outlined"
                    autoComplete={"password"}
                    textContentType="password"
                    secureTextEntry
                    value={user?.password}
                    style={{ marginBottom: 20, width: "100%" }}
                    onChangeText={text => onChange("password", text)}
                />
                <Button onPress={doLogin} mode="contained" style={{ width: "100%" }}>Log ind</Button>
            </ThemedView>
        </MainWrapper>
    )
}