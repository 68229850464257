import { View } from "react-native"
import { Button, Checkbox } from "react-native-paper"
import { DropFileInput } from "../../DropFileInput"
import { useEffect } from "react"

export const AppVersionFileInputFormIOS = (props: { onFileChange: (file: File | null) => void, file: File | null}) => {
    return (
        <>
            <DropFileInput onFileChange={props.onFileChange} mimetype="application/zip" />
            <span style={{ color: "red", textAlign: "center", display: (props.file != null && (props.file?.type != "application/x-zip-compressed" && props.file?.type != "application/zip") ? "block" : "none") }}>Filen skal være en zip.</span>
        </>
    )
}