import { signOut } from "firebase/auth";
import { useContext, useState } from "react";
import { AuthenticatedAxios } from "../../auth";
import { getApiUrl, getApiKey } from "../../configuration";
import { AuthContext } from "../../context";
import { AppInfo, AppVersion, UploadErrorResponse, UploadErrorResponseUpdateData, UploadResponse } from "../../models";
import { AppVersionUploadModal } from "./AppVersionUploadModal";
import { AppUploadAdditionalInfoForm, AppVersionNotesForm, AppVersionUserFacingNameForm, AppVersionFileInputForm } from "./Forms";
import { v4 as uuid } from 'uuid';

export const UploadModal = ({ visible, setUploadVisible, onAppUploaded }: { visible: boolean, setUploadVisible: (state: boolean) => void, onAppUploaded: (app: AppInfo, sendNotification: boolean, versionId?: number, versionCode?: string, versionNotes?: string) => void }) => {
    const { auth } = useContext(AuthContext);
    const [file, setFile] = useState<File | null>(null);
    const [requestPending, setRequestPending] = useState(false);
    const [appDidNotExist, setAppDidNotExist] = useState(false);
    const [sendNotificationToDiscord, setSendNotificationToDiscord] = useState(true);
    const [editingVersionNotes, setEditingVersionNotes] = useState(false);
    const [errorData, setErrorData] = useState<UploadErrorResponse | null>(null);
    const initialErrorFixDataValue = { apkName: "", versionCode: "", versionName: "", userFacingName: "" };
    const [errorFixData, setErrorFixData] = useState<UploadErrorResponseUpdateData>({ ...initialErrorFixDataValue })
    const [versionNotes, setVersionNotes] = useState("");
    const [uploadResponse, setResponse] = useState<UploadResponse | null>(null);
    const BYTES_PER_CHUNK = 10485760; // 10MB chunk sizes.
    var start = 0;
    var part = 0;
    var SIZE = 0;
    var xhr = new XMLHttpRequest();
    var fileGuid = '';
    const hideModal = () => {
        setAppDidNotExist(false);
        setUploadVisible(false);
    };
    const onFileChange = (fileResult: File | null) => {
        setFile(fileResult);
    }
    function sendRequest() {
        setRequestPending(true);
        SIZE = file!.size;
        start = 0;
        part = 0;
        xhr = new XMLHttpRequest();
        fileGuid = uuid();
        xhr.addEventListener("load", uploadComplete, false);
        uploadLargeFile();
    }
    const onErrorFixInputChange = (key: string, value: string) => {
        setErrorFixData(prev => { return { ...prev, [key]: value } })
    }
    async function uploadComplete(event: any) {
        if (event.currentTarget.status === 200) {
            if (start < SIZE) {
                start = start + BYTES_PER_CHUNK;
                part++;
                uploadLargeFile();
            } else {
                setRequestPending(true);
                try {
                    var response = await AuthenticatedAxios.get<UploadResponse>(`${getApiUrl()}/AppVersions/UploadLargeFileCompletion?guid=${fileGuid}&fileName=${file!.name}`);
                    setResponse(response.data)
                    setVersionNotes(response.data.versionNotes);
                    if (response.data.appExisted) {
                        setEditingVersionNotes(true);
                    }
                    else {
                        setAppDidNotExist(true);
                    }
                } catch (error: any) {
                    if (error && error.response && error.response.status == 401 && auth)
                        signOut(auth)
                    else if (error && error.response && error.response.status == 503) {
                        var d = error.response.data;
                        setErrorData(d);
                    }
                    else {
                        alert("Der skete en fejl under upload..");
                        console.log("Error during upload ", error);
                    }
                }
                finally{
                    setRequestPending(false);
                }
            }
        } else {
            console.log("Error uploading");
            setRequestPending(false);
        }

    }
    async function uploadLargeFile() {
        var blobFile = file!.slice(start, BYTES_PER_CHUNK + start);
        var fd = new FormData();
        fd.append("file", blobFile);
        fd.append("fileguid", fileGuid);
        fd.append('part', part.toString());
        fd.append('rawFileName', file!.name);
        fd.append('partsTotal', Math.ceil(SIZE / BYTES_PER_CHUNK).toString());
        xhr.open("POST", `${getApiUrl()}/AppVersions/UploadLargeFile?type=${"ANDROID"}`);
        var token = await auth?.currentUser?.getIdToken(); //StorageAuth.getCachedTokenAsync();
        if (token) {
            xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        }
        xhr.setRequestHeader("agent", "peasoup-versioning-backend");
        xhr.setRequestHeader("apiKey", `${getApiKey()}`);
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.send(fd);
    }
    const getApp = async (id: number) => {
        try {
            var res = await AuthenticatedAxios.get<AppInfo>(`${getApiUrl()}/AppVersions/GetApp/${id}`)
            return res.data;
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const getVersion = async (id: number) => {
        try {
            var res = await AuthenticatedAxios.get<AppVersion>(`${getApiUrl()}/AppVersions/GetVersion/${id}`)
            return res.data;
        } catch (error: any) {
            if (error && error.response && error.response.status == 401 && auth)
                signOut(auth)
        }
    }
    const saveAppName = async () => {
        if (uploadResponse) {
            try {
                await AuthenticatedAxios.get(`${getApiUrl()}/AppVersions/UpdateAppName/${uploadResponse?.appId}?appName=${uploadResponse?.suggestedName}`);
            } catch (error: any) {
                if (error && error.response && error.response.status == 401 && auth)
                    signOut(auth)
            }
            setEditingVersionNotes(true);
            setAppDidNotExist(false);
        }
    }
    const completeAppUploadWithInfo = async () => {
        if (errorData) {
            try {
                var res = await AuthenticatedAxios.get<UploadResponse>(`${getApiUrl()}/AppVersions/CompleteApkUploadWithInfo?tempAppIconFolder=${errorData?.tempAppIconFolder}&tempApkPath=${errorData?.tempApkPath}&apkName=${errorFixData.apkName}&versionName=${errorFixData.versionName}&versionCode=${errorFixData.versionCode}&userFacingName=${errorFixData.userFacingName}`);
                setResponse(res.data)
                setVersionNotes(res.data.versionNotes);
                if (res.data.appExisted) {
                    setEditingVersionNotes(true);
                }
                else {
                    setAppDidNotExist(true);
                }
            } catch (error: any) {
                if (error && error.response && error.response.status == 401 && auth)
                    signOut(auth)
            }
            setErrorData(null);
            setErrorFixData({ ...initialErrorFixDataValue });
        }
    }
    const saveVersionNotes = async () => {
        if (uploadResponse) {
            try {
                await AuthenticatedAxios.get(`${getApiUrl()}/AppVersions/UpdateVersionNotes/${uploadResponse.versionId}?notes=${versionNotes ? encodeURIComponent(versionNotes) : ""}`);
                var version = await getVersion(uploadResponse.versionId);
                var app = await getApp(uploadResponse?.appId);
                onAppUploaded(app!, sendNotificationToDiscord, version?.id, uploadResponse?.versionCode, versionNotes);
                setUploadVisible(false);
                setSendNotificationToDiscord(true);
                setEditingVersionNotes(false);
            } catch (error: any) {
                if (error && error.response && error.response.status == 401 && auth)
                    signOut(auth)
            }
        }
    }
    if (errorData != null)
        return (
            <AppVersionUploadModal hideModal={hideModal} visible={visible}>
                <AppUploadAdditionalInfoForm displayErrorMessage={true} formData={errorFixData} onInputChanged={onErrorFixInputChange} onSubmit={completeAppUploadWithInfo} requestPending={requestPending} />
            </AppVersionUploadModal>
        );
    else if (editingVersionNotes)
        return (
            <AppVersionUploadModal hideModal={hideModal} visible={visible}>
                <AppVersionNotesForm versionNotes={versionNotes} requestPending={requestPending} setVersionNotes={setVersionNotes} onSubmit={saveVersionNotes} />
            </AppVersionUploadModal>
        );
    else if (appDidNotExist)
        return (
            <AppVersionUploadModal hideModal={hideModal} visible={visible}>
                <AppVersionUserFacingNameForm uploadResponse={uploadResponse} onNameChange={(e) => {
                    setResponse(prev => { return { ...prev!, suggestedName: e } })
                }} onSubmit={saveAppName} requestPending={requestPending} />
            </AppVersionUploadModal>
        );
    else
        return (
            <AppVersionUploadModal hideModal={hideModal} visible={visible}>
                <AppVersionFileInputForm
                    onFileChange={onFileChange}
                    onSubmit={sendRequest}
                    requestPending={requestPending}
                    sendNotificationToDiscord={sendNotificationToDiscord}
                    setNotificationToDiscord={setSendNotificationToDiscord}
                    file={file}
                />
            </AppVersionUploadModal>
        );
}