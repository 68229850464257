export class UploadResponse {
    suggestedName: string;
    appId: number;
    appExisted: boolean;
    versionExisted: boolean;
    versionId: number;
    versionNotes: string;
    versionCode: string;
}
export class UploadErrorResponse {
    tempApkPath: string;
    tempAppIconFolder: string;
}
export class UploadErrorResponseUpdateData {
    apkName: string;
    versionName: string;
    versionCode: string;
    userFacingName: string;
}