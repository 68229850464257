export type Environment = {
    ApiUrl: string
};


let developmentHostname = '';
let productionHostname = '';
developmentHostname = "localhost:44369";
productionHostname = "smartbook-web.dk";
let apiKey = "d07c9f17-52d9-445a-b583-52a071a66f23";
const environments: { [x: string]: Environment } = {
    "dev": {
        ApiUrl: `https://${developmentHostname}/api`
    },
    "production": {
        ApiUrl: `https://${productionHostname}/api`
    }
}

export const currentEnvironment = (): Environment => {
    if (__DEV__) {
        return environments["dev"];
    } else {
        return environments["production"];
    }
}
export const getApiKey = (): string => {
    return apiKey;
}
export const getApiUrl = (): string => {
    return currentEnvironment().ApiUrl;
}