import React from "react"
import {
    LinkingOptions,
    NavigationContainer, DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AppVersions, HomePage, Login } from "./src";
import { PaperProvider } from "react-native-paper";
import { MD3DarkTheme, adaptNavigationTheme } from 'react-native-paper';
import merge from 'deepmerge';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RootStackParamList } from "./src/RootStackParamList";
import { AuthContext, DeviceProvider } from "./src/context";
import { Auth } from "firebase/auth";

const { DarkTheme } = adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme,
});
const CombinedDarkTheme = merge(MD3DarkTheme, DarkTheme);

const Stack = createNativeStackNavigator<RootStackParamList>();
export const Router = ({ isLoggedIn, auth }: { isLoggedIn: boolean, auth: Auth }) => {
    const linking: LinkingOptions<RootStackParamList> = {
        prefixes: ['https://versioning.smartbook-web.dk/'],
        config: {
            initialRouteName: "Home",
            screens: {
                Home: "Home",
                AppVersions: "AppVersions"
            },
        }
    };
    return (
        <PaperProvider theme={CombinedDarkTheme}>
            <AuthContext.Provider value={{ auth: auth }}>
                <SafeAreaProvider>
                    <NavigationContainer theme={CombinedDarkTheme} linking={linking}>
                        <DeviceProvider>
                            <Stack.Navigator>
                                {
                                    !isLoggedIn &&
                                    <>
                                        <Stack.Screen name="Login" component={Login} options={{ title: "Login", headerShown: false }} />
                                    </>

                                }
                                {
                                    isLoggedIn &&
                                    <>
                                        <Stack.Screen name="Home" component={HomePage} options={{ title: "Hjem", headerShown: false }} />
                                        <Stack.Screen name="AppVersions" component={AppVersions} options={{ title: "Versioner", headerShown: false }} />
                                    </>
                                }
                            </Stack.Navigator>
                        </DeviceProvider>
                    </NavigationContainer>
                </SafeAreaProvider>
            </AuthContext.Provider>
        </PaperProvider>
    )
}