import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Router } from './Router';
import { initializeApp } from "firebase/app";
import { useState } from 'react';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAQjUjrN0N14RkC82o4RmJdP5z3mdKVfho",
  authDomain: "peasoupversioningbackend.firebaseapp.com",
  projectId: "peasoupversioningbackend",
  storageBucket: "peasoupversioningbackend.appspot.com",
  messagingSenderId: "459279373926",
  appId: "1:459279373926:web:b6a45098d96e7624df3fa7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [appIsReady, setAppIsReady] = useState(false);
  
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      setIsLoggedIn(true);
      setAppIsReady(true);
    } else {
      setIsLoggedIn(false);
      setAppIsReady(true);
    }
  });
  if (!appIsReady) return null;
  return  <Router auth={auth} isLoggedIn={isLoggedIn} />
}
