import axios from "axios";

export class NotificationPayload {
    constructor(appId?: number, appName?: string, versionId?: number, versionName?: string, versionCode?: string, versionNotes?: string) {
        this.AppId = appId;
        this.AppName = appName;
        this.VersionId = versionId;
        this.VersionName = versionName;
        this.VersionCode = versionCode;
        this.VersionNotes = versionNotes;
    }
    AppId?: number;
    AppName?: string;
    VersionId?: number;
    VersionName?: string;
    VersionCode?: string;
    VersionNotes?: string;
    isValid(): boolean {
        if (!this.AppId || !this.AppName || !this.VersionId || !this.VersionName || !this.VersionCode)
            return false;
        return true;
    }
}

export const SendNotification = async (payload: NotificationPayload) => {
    if (!payload.isValid())
        return;
    let data = JSON.stringify({
        "content": `Notifikation om opdatering af ${payload.AppName} [Version ${payload.VersionName}(${payload.VersionCode})]
                    ${(payload.VersionNotes ? `\nNyt i denne version:\n--------\n${payload.VersionNotes}\n--------` : ``)}`,
        "embeds": [
            {
                "title": "Hent apk",
                "url": `https://smartbook-web.dk/api/File/DownloadVersion/${payload.VersionId}`
            },
            {
                "title": "Gå til versionssystemet",
                "url": `https://versioning.smartbook-web.dk/AppVersions?appId=${payload.AppId}`
            }
        ]
    });

    let config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        //Prod hook
        await axios.post('https://discord.com/api/webhooks/1294010810661863435/vj9dnL6LcLZPZi7YVilLqw0xTYX0YRsKyF5eIslTBqhQaSOyJsdWJUainoKeL3tZDN2h', data, config);
        //Test hook
        // await axios.post('https://discord.com/api/webhooks/1295369052239429762/HuL-JwRLxWsLN__ud750RkuwJyLhzl_av8e2YvAbO_hgLSXdLzJAvZgFT2Rr7mte7B-j', data, config);
        return true;
    } catch (error) {
        return false;
    }
}