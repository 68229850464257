import { PropsWithChildren } from "react"
import { Modal, Portal } from "react-native-paper"

export const AppVersionUploadModal = (props: PropsWithChildren<{ visible: boolean, hideModal: () => void }>) => {
    return (
        <Portal>
            <Modal visible={props.visible} onDismiss={props.hideModal} contentContainerStyle={{ width: 400, alignSelf: "center", padding: 20, backgroundColor: "#2a282f" }}>
                {props.children}
            </Modal>
        </Portal>
    )
}