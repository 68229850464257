import { View } from "react-native"
import { Button, Checkbox } from "react-native-paper"
import { DropFileInput } from "../../DropFileInput"

export const AppVersionFileInputForm = (props: { onFileChange: (file: File | null) => void, file: File | null, requestPending: boolean, onSubmit: () => void, sendNotificationToDiscord: boolean, setNotificationToDiscord: (val: boolean) => void }) => {
    return (
        <>
            <DropFileInput onFileChange={props.onFileChange} mimetype='application/vnd.android.package-archive' />
            <span style={{ color: "red", textAlign: "center", display: (props.file != null && props.file?.type != "application/vnd.android.package-archive" ? "block" : "none") }}>Filen skal være en apk.</span>
            <View style={{ flex: 1, alignItems: "center", marginTop: 20 }}>
                <Checkbox.Item style={{ padding: 0 }} label='Discord notifikation' disabled={props.requestPending} status={(props.sendNotificationToDiscord ? 'checked' : 'unchecked')} onPress={() => { props.setNotificationToDiscord(!props.sendNotificationToDiscord) }} />
                <span style={{ color: "rgb(255 135 135)", textAlign: "center", fontStyle: "italic", fontSize: "small", marginBottom: 20 }}>Notifikation om opdateringen kan også sendes fra changeloggen</span>
                <Button icon={"upload"} loading={props.requestPending} disabled={props.file == null || props.file.type != "application/vnd.android.package-archive" || props.requestPending} onPress={props.onSubmit} mode='outlined'>Upload</Button>
            </View>
        </>
    )
}