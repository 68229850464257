import { View } from "react-native"
import { TextInput, Button, Text } from "react-native-paper"
import { UploadResponse } from "../../../models"


export const AppVersionUserFacingNameForm = (props: { uploadResponse: UploadResponse | null, onNameChange: (name: string) => void, requestPending: boolean, onSubmit: () => void }) => {
    return (
        <>
            <Text style={{ marginBottom: 10 }}>App navn (foreslået af manifest fil)</Text>
            <TextInput style={{ marginBottom: 10 }} placeholder='App navn' value={props.uploadResponse?.suggestedName} onChangeText={props.onNameChange} />
            <View style={{ flex: 1, alignItems: "center", marginTop: 20 }}>
                <Button loading={props.requestPending} onPress={props.onSubmit} mode='outlined'>Fortsæt</Button>
            </View>
        </>
    )
}